<template>
  <v-container>
    <v-row>
      <v-col class="mb-4">
        <h1 class="text-h6 mb-3 primary--text">¿POR QUÉ AHORA BLOCKCHAIN?</h1>

        <p>
          Detrás de la tecnología queremos explicar por qué blockchain, y es sencillo.
          <span class="secondary--text">Blockchain nos ayuda a ser transparentes, a trazar, a medir</span> y hacerle más placentera la
          inversión a cualquiera.
        </p>
      </v-col>

      <!-- <v-col cols="12">
        <v-img :src="require('@/assets/images/why_now_blockchain.svg')" class="my-3" contain height="200" />
      </v-col> -->
    </v-row>
  </v-container>
</template>

<script>
export default {
  name: "WhyNowBlockchain",
  created() {
    if (this.$vuetify.breakpoint.smAndDown) {
      this.$emit("setImageBg", {
        backgroundImage: "url(" + require("@/assets/images/backgrounds/bgmobile-whyNowBlockchain.png") + ")"
      });
    } else {
      this.$emit("setImageBg", {
        backgroundImage: "url(" + require("@/assets/images/backgrounds/bg-whyNowBlockchain.png") + ")"
      });
    }
  }
};
</script>
